<template>
  <div class="refund-info-wrapper" v-loading.fullscreen="fullscreenLoading">
    <div class="refund-info-wrapper-inner margin-bottom-50">
      <div class="refund-amount-info">Refund amount： <span class="refund-amount">{{order && order.refundAmount}} {{order && order.refundCurrency}}</span></div>
      <el-form label-position="top" :model="form" :rules="rules" ref="formRef">
        <el-form-item label="Reason" prop="disputeReason" class="margin-top-20">
          <el-select v-placeholder v-model="form.disputeReason" placeholder="Reason" filterable autocomplete="false">
            <template v-if="order">
              <el-option v-for="(item, index) in order.disputeReason" :value="item.key" :label="item.value" :key="index"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="otherReason" v-if="form.disputeReason === 0">
          <el-input v-placeholder v-model="form.otherReason" placeholder="Reason description"></el-input>
        </el-form-item>
        <!-- 卡支付 -->
        <el-form-item v-if="order && order.type === 1" label="Last 4 digits of card number" prop="cardNumber" class="margin-top-20">
          <el-input v-placeholder v-model="form.cardNumber" placeholder="Last 4 digits of card number" maxlength="4"></el-input>
        </el-form-item>
        <!-- 本地支付 -->
        <el-form-item v-if="order && order.type === 2" label="Payment method" prop="paymentMethod" class="margin-top-20">
          <el-select v-placeholder v-model="form.paymentMethod" placeholder="Payment method" filterable autocomplete="false">
            <template v-if="order">
              <el-option v-for="(item, index) in order.lpms" :value="item.code" :label="item.name" :key="index"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="disputeDescription">
          <el-input type="textarea" v-model="form.disputeDescription" v-placeholder
            :rows="6"
            placeholder="Supplement the description, so that we can better handle your refund."></el-input>
        </el-form-item>
        <el-form-item class="margin-top-26">
          <el-button type="primary" class="button" @click="submit" :disabled="order === null">Submit</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getInfo, refund } from '@/api/refund';
import { showMessage, getBrowerInfo } from '@/utils/tools/utils';

export default {
  data() {
    return {
      form: {
        disputeReason: '',
        otherReason: '',
        cardNumber: '',
        paymentMethod: '',
        disputeDescription: '',
      },
      rules: {
        disputeReason: [
          { required: true, message: 'Please enter a valid reason', trigger: 'blur' },
        ],
        otherReason: [
          { required: true, message: 'Please enter a valid reason description', trigger: 'blur' },
        ],
        cardNumber: [
          { required: true, message: 'Please enter a valid last 4 digits of card number', trigger: 'blur' },
          { pattern: /^\d{4}$/, message: 'Please enter a valid last 4 digits of card number', trigger: 'blur' },
        ],
        paymentMethod: [
          { required: true, message: 'Please enter a valid payment method', trigger: 'blur' },
        ],
        disputeDescription: [
          { required: true, message: 'Please enter a valid description', trigger: 'blur' },
        ],
      },
      order: null,
      fullscreenLoading: true,
      txnId: this.$route.query.txnId,
    };
  },
  created() {
    getInfo(this.txnId).then((res) => {
      if (res.respCode === '20000') {
        this.txnType = res.data.type;
        this.order = res.data;
      } else {
        showMessage(res.respMsg);
      }
      this.fullscreenLoading = false;
    }).catch(() => {
      this.fullscreenLoading = false;
      showMessage('Network error, please try again later');
    });
  },
  methods: {
    submit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          const browerInfo = getBrowerInfo();
          const submitData = {
            txnId: this.txnId,
            disputeReason: this.form.disputeReason,
            otherReason: this.form.disputeReason === 0 ? this.form.otherReason : '',
            verifyData: this.order.type === 1 ? this.form.cardNumber : this.form.paymentMethod,
            disputeDescription: this.form.disputeDescription,
            ...browerInfo,
          };
          refund(submitData).then((res) => {
            if (res.respCode === '20000') {
              this.$router.push({
                path: '/refund/success',
              });
            } else {
              this.fullscreenLoading = false;
              showMessage(res.respMsg);
            }
          }).catch(() => {
            this.fullscreenLoading = false;
            showMessage('Network error, please try again later');
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/element-variables.scss';
.refund-info-wrapper {
  color: $color-text-primary;
  width: 575px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  text-align: left;
  .refund-info-wrapper-inner {
    padding-left: 10px;
    padding-right: 10px;
    .refund-amount-info {
      background: $color-text-bg;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 800;
      color: $color-text-primary;
      line-height: 20px;
      padding: 12px 10px;
      .refund-amount {
        color: $color-text-hight;
        margin-left: 20px;
      }
    }
    .button {
      width: 100%;
    }
  }
  .el-form-item__label {
    font-size: 14px;
    font-weight: 800;
    line-height: 32px;
    color: $color-text-primary;
    padding-bottom: 0;
  }
  .el-input__inner {
    font-size: 14px;
    font-weight: bold;
    color: $color-text-primary;
    &::-webkit-input-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &::-moz-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &:-moz-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &::placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
  }
  .el-textarea__inner {
    font-size: 14px;
    font-weight: bold;
    color: $color-text-primary;
    overflow-y: auto;
    &::-webkit-input-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &::-moz-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &:-moz-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
    &::placeholder {
      color: $color-placeholder !important;
      font-weight: normal;
    }
  }
}
@media screen and (max-width: 1140px) {
  .refund-info-wrapper {
    padding-top: 0;
  }
}
</style>
